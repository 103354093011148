<template>
    <!--在此处添加渲染的内容-->
    <div>
        <el-upload
                class="upload-demo"
                ref="upload"
                drag
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
                :http-request="handleHttpRequest"
                :headers="uploadHeaders"
                :limit="files"
                :disabled="disabled"
                multiple
                action=""
                :file-list="fileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div slot="tip" class="el-upload__tip">上传文件大小不能超过 1G</div>
        </el-upload>
    </div>
</template>
 
<script type="text/ecmascript-6">
    import Client from './js/Client'
    //将渲染的内容导出
    export default{
        props: {},
        data(){
            return {
                // region: 'oss-cn-beijing',
                // bucket: '',//这里选择OSS容器
                percentage: 0,
                url: '',//后台获取token地址
                ClientObj: null,
                dataObj: {},
                expiration: '',
                fileList: [],
                files: 10,
                uploadHeaders: {
                    authorization: '*'
                },
                disabled: false,
            }
        },
        methods: {
            getDate(){
                const date = new Date(),
                    year = date.getFullYear(),
                    month = date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`,
                    day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`,
                    hh = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`,
                    mm = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
                    return `${year}${month}${day}${hh}${mm}`;
            },
           async getAliToken(){ //获取Token
           let resData = await this.$Api.OssUpload.getQueryToken()
          //  console.log(resData)
           this.dataObj = {
                  region: 'oss-cn-beijing',
                  bucket: resData.data.bucket,
                  accessKeyId: resData.data.accessKeyId,
                  accessKeySecret: resData.data.accessKeySecret,
                  stsToken: resData.data.stsToken
              };
                // return new Promise((resolve, reject) => {
                //     this.$axios({
                //         method: 'POST',
                //         url: this.url
                //     }).then(res => {
                //         if (res.success) {
                //             const {expiration, tempAk, tempSk, token} = res.data;
                //             this.expiration = expiration;
                //             this.dataObj = {
                //                 region: this.region,
                //                 bucket: this.bucket,
                //                 accessKeyId: tempAk,
                //                 accessKeySecret: tempSk,
                //                 stsToken: token
                //             };
                //             resolve(true);
                //         } else {
                //             reject(false);
                //         }
                //     }).catch(err => {
                //         console.log(err);
                //         reject(false);
                //     })
                // })
            },
            beforeUpload(file){
            console.log(file)
            // this.getAliToken()
                // return new Promise((resolve, reject) => {
                //     this.getAliToken().then(response => {
                //         if (response) {
                //             resolve(response);
                //         } else {
                //             reject(response);
                //         }
                //     }).catch(err => {
                //         console.log(err);
                //         reject(err);
                //     });
                // })
            },
            async handleHttpRequest(option){ //上传OSS
                console.log(option.file)
                try {
                  console.log(option)
                     let vm = this;
                    vm.disabled = true;
                    const client = Client(this.dataObj), file = option.file;
                    const random_name = this.random_string(6) + '_' + new Date().getTime() + '.' + file.name.split('.').pop();
                    // const data = new OSS.Buffer(file.name);
                    const result = await client.put(file.name,file);
                          console.log(result);



                    // let vm = this;
                    // vm.disabled = true;
                    // const client = Client(this.dataObj), file = option.file;
                    // //随机命名
                    // const random_name = this.random_string(6) + '_' + new Date().getTime() + '.' + file.name.split('.').pop();
                    // // 分片上传文件
                    // await client.multipartUpload(random_name, file, {
                      
                    //     progress: async function (p) {
                    //       console.log('1234',p);
                    //         let e = {};
                    //         e.percent = p * 100;
                    //         option.onProgress(e)
                    //     }
                    // }).then(({res}) => {
                    //     console.log('123',res);
                    //     if (res.statusCode === 200) {
                    //         // option.onSuccess(ret)
                    //         return res.requestUrls
                    //     } else {
                    //         vm.disabled = false;
                    //         option.onError('上传失败');
                    //     }
                    // }).catch(error => {
                    //     vm.disabled = false;
                    //     option.onError('上传失败');
                    // });
 
                } catch (error) {
                    console.error(error);
                    this.disabled = false;
                    option.onError('上传失败');
                }
            },
            handleSuccess(response, file, fileList){
                // console.log(response);
                // console.log(file);
                // console.log(fileList);
            },
            // 随机生成文件名
            random_string(len) {
                len = len || 32;
                let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678', maxPos = chars.length, pwd = '';
                for (let i = 0; i < len; i++) {
                    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
                }
                return pwd;
            }
        },
        // watch: {
        //     url(val){
        //         if (val) {
        //             this.urls.push(val);
        //         }
        //     }
        // },
        components: {},
        computed: {},
        watch: {},
        created(){
            this.getAliToken();
        },
        mounted(){
        },
 
    }
</script>
 
<style scoped>
    /**渲染内容的样式**/
 
</style>

